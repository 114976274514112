define("@videoly/ember-components/components/v-datepicker/time", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="v-datepicker-time {{if (eq @type 'double') 'double'}}"
    data-test-datepicker-time
    {{did-insert @registerTimeUse}}
    ...attributes
  >
    {{#if @isVisible}}
      <input
        class="v-datepicker-time__input"
        type="time"
        pattern="[0-9]{2}:[0-9]{2}"
        value={{get @selectedTime 'start'}}
        data-test-datepicker-time-input
        {{on 'change' (fn this.handleChange 'start')}}
        aria-label="start time"
      />
      {{#if (eq @type 'double')}}
        <div class="delimiter"></div>
        <input
          class="v-datepicker-time__input"
          type="time"
          pattern="[0-9]{2}:[0-9]{2}"
          value={{get @selectedTime 'end'}}
          data-test-datepicker-time-input
          {{on 'change' (fn this.handleChange 'end')}}
          aria-label="end time"
        />
      {{/if}}
      {{yield}}
    {{/if}}
  </div>
  
  */
  {
    "id": "tznYez+l",
    "block": "[[[11,0],[16,0,[29,[\"v-datepicker-time \",[52,[28,[37,1],[[30,1],\"double\"],null],\"double\"]]]],[24,\"data-test-datepicker-time\",\"\"],[17,2],[4,[38,2],[[30,3]],null],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[11,\"input\"],[24,0,\"v-datepicker-time__input\"],[24,\"pattern\",\"[0-9]{2}:[0-9]{2}\"],[16,2,[28,[37,3],[[30,5],\"start\"],null]],[24,\"data-test-datepicker-time-input\",\"\"],[24,\"aria-label\",\"start time\"],[24,4,\"time\"],[4,[38,4],[\"change\",[28,[37,5],[[30,0,[\"handleChange\"]],\"start\"],null]],null],[12],[13],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"double\"],null],[[[1,\"      \"],[10,0],[14,0,\"delimiter\"],[12],[13],[1,\"\\n      \"],[11,\"input\"],[24,0,\"v-datepicker-time__input\"],[24,\"pattern\",\"[0-9]{2}:[0-9]{2}\"],[16,2,[28,[37,3],[[30,5],\"end\"],null]],[24,\"data-test-datepicker-time-input\",\"\"],[24,\"aria-label\",\"end time\"],[24,4,\"time\"],[4,[38,4],[\"change\",[28,[37,5],[[30,0,[\"handleChange\"]],\"end\"],null]],null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,6,null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@type\",\"&attrs\",\"@registerTimeUse\",\"@isVisible\",\"@selectedTime\",\"&default\"],false,[\"if\",\"eq\",\"did-insert\",\"get\",\"on\",\"fn\",\"yield\"]]",
    "moduleName": "@videoly/ember-components/components/v-datepicker/time.hbs",
    "isStrictMode": false
  });
  let VCalendarTimeComponent = _exports.default = (_class = class VCalendarTimeComponent extends _component2.default {
    handleChange(rangeType, _ref2) {
      let {
        target
      } = _ref2;
      const newTime = {
        ...this.args.selectedTime,
        [rangeType]: target.value
      };
      return this.args.handleChange(newTime);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VCalendarTimeComponent);
});