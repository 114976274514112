define("@videoly/ember-components/components/v-code-editor", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "ember-concurrency", "@videoly/ember-components/utils/create-event"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _emberConcurrency, _createEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="v-code-editor">
    {{#if this.oneline}}
      <v-textarea
        data-test-code-editor-oneline
        text={{@value}}
        autoresize={{true}}
        placeholder={{@placeholder}}
        oneline={{true}}
        monospace={{true}}
        theme={{@theme}}
        {{on 'textareaInput' this.handleValueChange }}
        {{on 'textareaReady' (or @handleReady this.noop) }}
        {{on 'textareaClear' (or @handleClear this.noop) }}
        {{on 'textareaFocus' (or @handleFocus this.noop) }}
        {{on 'textareaBlur' (or @handleBlur this.noop) }}
      />
    {{else}}
      <EmberAce
        data-test-code-editor-multiline
        {{will-destroy this.clearEditorInstance}}
        class="{{this.aceEditorClassnames}}"
        @mode={{concat 'ace/mode/' this.mode}}
        @value={{@value}}
        @lines={{or @lines 5}}
        @ready={{this.onEditorInit}}
        @update={{this.handleValueChange}}
      />
    {{/if}}
  
    {{yield}}
  </div>
  
  */
  {
    "id": "7MoZiSGN",
    "block": "[[[10,0],[14,0,\"v-code-editor\"],[12],[1,\"\\n\"],[41,[30,0,[\"oneline\"]],[[[1,\"    \"],[11,\"v-textarea\"],[24,\"data-test-code-editor-oneline\",\"\"],[16,\"text\",[30,1]],[16,\"autoresize\",true],[16,\"placeholder\",[30,2]],[16,\"oneline\",true],[16,\"monospace\",true],[16,\"theme\",[30,3]],[4,[38,1],[\"textareaInput\",[30,0,[\"handleValueChange\"]]],null],[4,[38,1],[\"textareaReady\",[28,[37,2],[[30,4],[30,0,[\"noop\"]]],null]],null],[4,[38,1],[\"textareaClear\",[28,[37,2],[[30,5],[30,0,[\"noop\"]]],null]],null],[4,[38,1],[\"textareaFocus\",[28,[37,2],[[30,6],[30,0,[\"noop\"]]],null]],null],[4,[38,1],[\"textareaBlur\",[28,[37,2],[[30,7],[30,0,[\"noop\"]]],null]],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],[[24,\"data-test-code-editor-multiline\",\"\"],[16,0,[29,[[30,0,[\"aceEditorClassnames\"]]]]],[4,[38,5],[[30,0,[\"clearEditorInstance\"]]],null]],[[\"@mode\",\"@value\",\"@lines\",\"@ready\",\"@update\"],[[28,[37,4],[\"ace/mode/\",[30,0,[\"mode\"]]],null],[30,1],[28,[37,2],[[30,8],5],null],[30,0,[\"onEditorInit\"]],[30,0,[\"handleValueChange\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[18,9,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@value\",\"@placeholder\",\"@theme\",\"@handleReady\",\"@handleClear\",\"@handleFocus\",\"@handleBlur\",\"@lines\",\"&default\"],false,[\"if\",\"on\",\"or\",\"ember-ace\",\"concat\",\"will-destroy\",\"yield\"]]",
    "moduleName": "@videoly/ember-components/components/v-code-editor.hbs",
    "isStrictMode": false
  });
  const editorStates = {
    NORMAL: 'normal',
    WARNING: 'warning',
    INFO: 'info',
    ERROR: 'error'
  };
  const EDITOR_DEBOUNCE_TIMEOUT = 100;
  let VCodeEditorComponent = _exports.default = (_dec = (0, _emberConcurrency.task)({
    maxConcurrency: 1,
    restartable: true
  }), _class = class VCodeEditorComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "state", _descriptor, this);
    }
    noop() {
      return null;
    }
    get oneline() {
      var _this$args$oneline;
      return (_this$args$oneline = this.args.oneline) !== null && _this$args$oneline !== void 0 ? _this$args$oneline : false;
    }
    get mode() {
      var _this$args$mode;
      return (_this$args$mode = this.args.mode) !== null && _this$args$mode !== void 0 ? _this$args$mode : 'text';
    }
    get aceEditorClassnames() {
      return `v-code-editor--ace-editor v-code-editor--${this.mode} v-code-editor--${this.state}`;
    }
    *handleEditorAnnotationsTask() {
      yield (0, _emberConcurrency.timeout)(EDITOR_DEBOUNCE_TIMEOUT);
      const container = this._aceEditor.container;
      const annot = this._aceEditor.getSession().getAnnotations();
      const errors = annot.length ? annot : [];
      let event = (0, _createEvent.default)('validationStateChange', {
        errors
      });
      this.setEditorState(annot);
      container.dispatchEvent(event);
    }
    setEditorState(annot) {
      if (annot.length === 0) {
        this.state = editorStates.NORMAL;
      } else {
        if (annot.find(a => a.type === editorStates.WARNING) || annot.find(a => a.type === editorStates.INFO)) {
          this.state = editorStates.WARNING;
        }
        if (annot.find(a => a.type === editorStates.ERROR)) {
          this.state = editorStates.ERROR;
        }
      }
    }
    onEditorInit(editor) {
      this._aceEditor = editor;
      this._aceEditor.getSession().on('changeAnnotation', () => {
        this.handleEditorAnnotationsTask.perform();
      });
      this.args.handleReady && this.args.handleReady(editor);
      return editor;
    }
    clearEditorInstance() {
      this._aceEditor.destroy();
      this._aceEditor = null;
    }
    handleValueChange(value) {
      let data = value;
      if (value instanceof Event) {
        data = value.detail.target.value;
      }
      this.args.handleValueChange(data);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return editorStates.NORMAL;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleEditorAnnotationsTask", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleEditorAnnotationsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEditorState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setEditorState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEditorInit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onEditorInit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearEditorInstance", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearEditorInstance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleValueChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleValueChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VCodeEditorComponent);
});